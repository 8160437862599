@import url("https://cdnjs.cloudflare.com/ajax/libs/toastr.js/2.1.3/toastr.min.css");

//css
@import "~bootstrap/scss/bootstrap";

::-ms-reveal {
  display: none;
}

#swal2-html-container {
  white-space: pre-line;
}

.main-content {
  padding: 15px 15px;
  padding-top: 0px;
  padding-bottom: 40px;
  margin-left: 240px !important;
  transition: all 0.5s ease;
  background-repeat: repeat;

  &.nav-collapsed {
    margin-left: 60px !important;
    height: 100%;
    transition: all 0.5s ease;
    background-repeat: repeat;
  }
}

.card-header {
  text-transform: none !important;
}

.jodit-wysiwyg ul {
  margin-left: 7px; /* Espacio para el texto enriquecido, para mostrar las viñetas */
}

// Ajuste para hacer que la barra de herramientas del editor sea fija
.jodit-workplace
{
  min-height: 300px !important;
}
.jodit-wysiwyg
{
  position: absolute !important;
  overflow: auto;
  min-height: 300px !important;
}
// .jodit-ui-group__eye {
//   display: none !important
// }

//@extend

// icon
@import "./assets/fontawesome-pro/css/all.css";
@import "~font-awesome/css/font-awesome.min.css";
@import "~simple-line-icons/css/simple-line-icons.css";
@import "~weather-icons/css/weather-icons.css";

// scss
@import "./assets/scss/style.scss";

///jvectormap
@import "./assets/css/jvectormap";
//ReactToastify
@import "~react-toastify/dist/ReactToastify.css";
