.modal-dialog {
  max-width: 1000px !important;
}

/* //NUEVA ESPECIALIDAD */
.modal-content {
  border-radius: 20px !important;
}

.swal2-cancel {
  background-color: #bf0000 !important;
}

.swal2-warning {
  border-color: #bf0000 !important;
  color: #bf0000 !important;
}

.modal {
  overflow-y: hidden !important;
}
