.custom-card {    
    box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
  }

  .contenedor-subcategorias{    
      display: flex;
      justify-content: space-between;
      align-items: center;    
  }

  .nombre-subcategoria
  {
    color: #4b71fa;
    cursor: pointer;
  }

  .input-subcategoria
  {
    width:100%;
    margin-right:15px
  }

  .eliminar-subcategoria
  {    
      border: 1px solid black;
      padding: 5px 10px;
      cursor: pointer;    
  }