.contenedor-categorias .form-group
{
    margin-bottom: 0px !important;    
}

.badges-subcategorias
{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    min-height: 50px;
}