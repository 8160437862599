/* =============   Topbar  ============= */

.top-bar {
  height: 60px;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 100;
}

.avtar-dropdown{
    &:hover, &:focus, &:visited{
    background-color: transparent !important;
    border: none;
  }
}

.top-bar {
  .dropdown-toggle{
  &::after{
    // update bootstrap 4.4.1
  // right:10px;
  right:2px;
    


  }
  &:hover, &:focus, &:visited{
    background-color: transparent !important;
    border: none;
  }
  }
  .dropdown-menu{
  
  .dropdown-header{
    padding:15px 15px;
    color:#fff;
    background:$primary;
    border-top-left-radius:3px;
    border-top-right-radius:3px;
    > a{
     color:#fff;
    }
    }
  }

   &.light-top-bar {
    background: #fff;
    -webkit-box-shadow: 1px 0 20px rgba(0, 0, 0, 0.1);
    box-shadow: 1px 0 20px rgba(0, 0, 0, 0.1);
    .top-right-nav > li > a {
      color: #909fa7;
      &:hover {
        color: #212121;
      }
      img {
        border: 2px solid rgba(0, 0, 0, 0.05);
      }
    }
  }
  &.primary-top-bar {
    background-image: linear-gradient(to right, #4b71fa, #924bfa);
    .top-right-nav > li > a {
      color: #fff;
      &:hover {
        color: #fff;
      }
      img {
        border:none;
        margin-top:-5px;
      }
    }
    .search-form {
      i {
        color: #fff;
      }
    }
    .left-nav-toggle a {
      color: #fff;
    }
  }

}


.top-bar.primary-top-bar .search-form .form-control::-webkit-input-placeholder {
	color: #fff;
}

.top-bar.primary-top-bar .search-form .form-control:-moz-placeholder {
	color: #fff;
}

.top-bar.primary-top-bar .search-form .form-control::-moz-placeholder {
	color: #fff;
}

.top-bar.primary-top-bar .search-form .form-control:-ms-input-placeholder {
	color: #fff;
}

.admin-logo {
  display: block;
  margin-left: -15px;
  float: left;
  width: 240px;
  text-align: center;
  padding-left: 0px;
  height: 60px;
  line-height: 55px;
  background: #fff;

  // old
  // -moz-transition: all 0.0s;
  // -o-transition: all 0.0s;
  // -webkit-transition: all 0.0s;
  // transition: all 0.0s;

  -moz-transition: all .5s ease;
  -o-transition: all .5s ease;
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
  overflow: hidden
  ;
  &.dark-logo{
  background:#25313f;
  }
  h1 {
    color: #fff;
    font-size: 28px;
    line-height: 55px;
    text-transform: uppercase;
    font-weight: 800;
    margin: 0px;
    img {}
  }
  > .default {
    display: none;
  }
  sub {
    font-size: 10px;
    font-style: italic;
    color: #fff;
    opacity: 0.8;
    font-weight: 300;
  }
}

.top-right-nav {
  margin: 0;
  padding: 0;
  float: right;
  padding-right: 0px;
  .dropdown-menu{
    box-shadow: 0 10px 70px 0 rgba(0,0,0,.25);
    border:0;
    border-radius:3px;
  }
  > li {
    display: block;
    float: left;
    position: relative;
    padding: 0px;
    i {
      vertical-align: middle;
      display: inline-block;
      margin-left: 0;
      font-size: 16px;
    }
    > a {
      display: block;
      line-height: 60px;
      padding: 0px 15px;
      color: #fff;
      img {
        border: 2px solid rgba(255, 255, 255, 0.5);
        margin-right: 0px;
        width: 35px;
      }
    }
    > button {
      display: block;
      line-height: 60px;
      padding: 0px 15px;
      color: #fff;
      background-color: transparent !important;
      border: none;

      &.dropdown-toggle{
        &:after{
          top: 0 !important;
        }
      }

      img {
        border: 2px solid rgba(255, 255, 255, 0.5);
        margin-right: 0px;
        width: 35px;
      }
    }
  }
  // @extend .d-flex;
  // @extend .justify-content-end;
}

.icons-dropdown .badge {
  position: absolute;
  top: 13px;
  right: 22px;
  border-radius: 20px;
}

.left-nav-toggle, .left-nav-collapsed {
  margin-left: 0px;
  line-height: 60px;
  float: left;
}

.left-nav-toggle a, .left-nav-collapsed a {
  display: block;
  color: #fff;
  font-size: 15px;
  width: 50px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  
  background-color: transparent;
  border: none;
}

.search-form {
  display: block;
  float: left;
  position: relative;
  margin-left: 30px;
  margin-top: 13px;
  .form-control {
    width: 240px;
    border: 0px;
    background: rgba(255, 255, 255, 0.1);
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -ms-box-shadow: none;
    box-shadow: none;
    border: 1px solid rgba(255, 255, 255, 0.2);
    height: 34px;
    color: #fff;
    border-radius:30px;
    font-size:13px;
    &:focus {
      background-color: rgba(255, 255, 255, 0.5);
    }
    &::-webkit-input-placeholder, &:-moz-placeholder, &::-moz-placeholder, &:-ms-input-placeholder {
      color: #fff;
    }
  }
  .btn-search {
    position: absolute;
    right: 15px;
    top: 5px;
    border: 0;
    background: transparent;
    color: #fff;
    padding: 0;
  }
}



.light-top-bar {
  .left-nav-toggle a, .left-nav-collapsed a {
    color: #909fa7;
    background-color: transparent;
    border: none;
  }
}

.top-bar.light-top-bar {
  .search-form {
    i {
      color: #909fa7;
    }
    .form-control {
      border: 1px solid #ccc;
      color: #333;
      background-color: #fff;
      border-radius:30px;
      font-size:13px;
      &::-webkit-input-placeholder, &:-moz-placeholder, &::-moz-placeholder, &:-ms-input-placeholder {
        color: #4d4d4d;
      }
    }
  }
  .left-nav-toggle a {
    color: #999;
  }
}

.header-button a{

   line-height:0!important;
    padding: 0!important;
    }