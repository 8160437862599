
#world-map-markers{
    svg{
        g{
            g{
                path{
                    fill: #c9d6de;
                }
            }
        }

    }
}

.card-item
{
    display: flex;
    justify-content: space-between;
}

.title
{
    max-width: 80px;
    min-width: 80px;
}